@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Genos:wght@400&display=swap');

:root {
  --main-violet: #1E8BC4;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;


}
.carousel-container-custom {
  margin: auto;
 
}
.comfortaa {
  font-family: 'Comfortaa';
}

.genos {
  font-family: 'Genos';
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.btn-primary {
  border-width: 0 !important;
  color: white !important;
  background: linear-gradient(to right, #47a0ff, rgb(30, 139, 196)) !important;
}

.btn-primary:hover {
  background: linear-gradient(rgb(73, 162, 255), rgb(73, 162, 255)) !important;
  color: white !important;
}

.btn-primary:focus {
  background: rgb(73, 162, 255) !important;
  color: white !important;
}

@property --myColor1 {
  syntax: '<color>';
  initial-value: rgb(255, 255, 255);
  inherits: false;
}

@property --myColor2 {
  syntax: '<color>';
  initial-value: rgb(255, 255, 255);
  inherits: false;
}

.btn-outline-primary {
  
  border-width: 0 !important;
  background: linear-gradient(to right, var(--myColor1), var(--myColor2));
  
}

.btn-outline-primary:hover {
  --myColor1: rgb(71, 160, 255);
  --myColor2: rgb(30, 139, 196);
  color: white !important;
  transition: --myColor1 0.5s, --myColor2 0.5s;

}

.btn-outline-primary:focus {
  background:  rgb(30, 139, 196) !important;
  color: white !important;
}


.active {
  background: linear-gradient(to left, #47a0ff, rgb(30, 139, 196)) !important;
  color: white !important;
}

.icon {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.icon.show {
  opacity: 1;
}

.resize-icon {
  color: rgb(58, 58, 58);
}

.resize-bar:hover .resize-icon {
  transition: 200ms;
  color: rgb(150, 150, 150)
}

.resize-icon {
  transition: 200ms;
}

.nav-item {
  transition: 200ms;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  resize: vertical;

}

@keyframes change-color-to-green {
  from {
    color: black
  }

  to {
    color: #699f4c;
  }
}

@keyframes change-color-to-red {
  from {
    color: black
  }

  to {
    color: #FF5555;
  }
}

@keyframes change-opacity-to-zero {
  from {
    opacity: 1
  }

  to {
    opacity: 0
  }
}

@keyframes change-opacity-to-one {
  from {
    opacity: 0;
  }

  to {
    opacity: 1
  }
}

.custom-marker-container {
  display: inline-flex;
  position: relative;
  top: -37px;
  left: -14px;
}

@keyframes fadeIn {
  from {
    filter: blur(5rem)
  }

  to {
    filter: blur(0)
  }
}

@keyframes fadeOut {
  from {
    filter: blur(0)
  }

  to {
    filter: blur(5rem)
  }
}

.appear {
  animation: fadeIn 1s forwards;
}

.disappear {
  animation: fadeOut 1s forwards;

}

.custom-marker-container .badge {
  display: flex;
  align-items: center;
}

body {

  box-sizing: border-box;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  display: flex;

  flex-direction: column;
}


html {

  font-size: 14px;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overscroll-behavior-y: contain;

}

@keyframes grow {
  from {
    height: 0px
  }

  to {
    height: 200px
  }
}

.increase-height {
  animation: grow 1s ease-in-out forwards;
}

@keyframes shrink {
  to {
    height: 0px
  }

  from {
    height: 200px
  }
}

.decrease-height {
  animation: shrink 1s ease-in-out forwards;
}

.layer-over-map {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #c0c0c090;
  
  pointer-events: none;

  animation: fadeIn;
  z-index: 999;
}

.fade-enter {
  opacity: 0;

}

.fade-enter-active {
  opacity: 1;
  transition: 1s ease-in-out;
}

.fade-exit {
  opacity: 1;
  max-height: 100%;
}

.fade-exit-active {
  opacity: 0;
  max-height: 0px;
  transition: 1s ease-in-out;
}

.navbar-brand {
  height: 50px;
  width: 210px;
  background-size: contain;
  margin-left: 20px;
  background-position: left center;
  background-repeat: no-repeat;

}

.navbar-toggler {
  margin-left: 10px;
}